import { useUserStore } from '@/store/user';

export const useNumberUtils = () => {
  const { language, currency } = useUserStore();
  const { n } = useI18n();

  const formatPrice = (value: number) => {
    return n(value, {
      style: 'currency',
      currency,
      locale: language,
      currencyDisplay: 'narrowSymbol',
    });
  };

  return {
    formatPrice,
  };
};
